<rap-breadcrumb-edit-new [exploreLabel]="'Rap Almanac'" [exploreLink]="'/dashboard/explore'"
  [exploreImage]="'../assets/images/filled.svg'" [subLabel]="'Functions'" [subLink]="'/dashboard/functions'"
  [subId]="{ id: editableFunction?.id  }" [isNew]="isNew" [isView]="false" [filledImage]="'../assets/images/filled.svg'"
  [title]=""></rap-breadcrumb-edit-new>

<div class="functions-section">
  <div class="section-title">
    {{ title }}
  </div>
  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <div class="card">
    <div class="card-body">
      <form [formGroup]="functionForm" (ngSubmit)="addUpdateFunction()">
        <div class="form-group">
          <label for="name">Name</label>
          <div class="input-container">
            <input type="text" class="search-input" [class.submitted]="submitted" formControlName="name"
              placeholder="Enter an function name" />
          </div>
          <div *ngIf="submitted && f['name'].errors" class="rap-error-text">
            <span *ngIf="f['name'].errors?.['required']">Name is required</span>
          </div>
        </div>

        <div class="action-btns">
          <rap-rect-primary [label]="'Save'" [btnClass]="'btn btn-dark rect-primary-btn1'"
            (clickEvent)="addUpdateFunction()"></rap-rect-primary>
          <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn1'"
            (clickEvent)="onReset()"></rap-rect-primary>
          <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn1'"
            (clickEvent)="Cancel()"></rap-rect-primary>
        </div>
      </form>
    </div>
  </div>
</div>