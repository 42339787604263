import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CreateProducerRequest } from 'src/app/models/producers/create-producer-request.model';
import { Producer } from 'src/app/models/producers/producer.model';
import { UserData } from 'src/app/models/user-data.model';
import { ProducersService } from '../producers.service';

@Component({
  selector: 'rap-add-edit-producer-ui',
  templateUrl: './add-edit-producer.component.html',
  styleUrls: ['./add-edit-producer.component.scss'],
})
export class AddEditProducerComponent {
  @Input() title: string;
  @Input() editableProducer: Producer;
  @Input() errorMessage: string;
  @Input() userDetails: UserData;
  @Output() addProducer = new EventEmitter<CreateProducerRequest>();
  @Input() isContributions: boolean;
  producerForm: FormGroup;
  submitted: boolean = false;
  isLoading = false;
  subTitle = "Database";
  constructor(private formBuilder: FormBuilder, private router: Router, private producerService: ProducersService) { }

  ngOnInit() {
    this.producerForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
    });
    console.log(this.isContributions)
    if (this.isContributions) {
      this.subTitle = "Contributions";

    }
  }

  ngOnChanges() {
    if (this.editableProducer) {
      this.producerForm.patchValue({
        name: this.editableProducer.name,
      });
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.producerForm.controls;
  }

  addUpdateProducer() {
    this.submitted = true;
    if (this.producerForm.invalid) {
      return;
    }
    this.isLoading = true;
    let createProducerRequest: CreateProducerRequest = {
      data: {
        name: this.producerForm.value.name,
      },
    };

    // this.addProducer.emit(createProducerRequest);
    this.producerService.addNewProducerNew(createProducerRequest).subscribe((result) => {
      this.isLoading = false;
      this.onCancel();
    });
  }

  onReset() {
    this.producerForm = this.formBuilder.group({
      name: ['', Validators.required],

    });
  }

  onCancel() {
    var url = "/dashboard/database/producers";
    if (this.isContributions) {
      url = "/dashboard/contributions/producers";
    }
    console.log(this.isContributions)
    this.router.navigateByUrl(url, { skipLocationChange: true, state: { isContributions: this.isContributions } }
    );
  }
}
