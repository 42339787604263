<!-- <rap-breadcrumb-view-new [routerLink]="'/dashboard/explore'" [clickableLabel]="'Rap Almanac'"
    [clickableIcon]="'../assets/images/filled.svg'" [title]="title" [nonClickableLabel]="'Songs'"
    [nonClickableIcon]="'../assets/images/filled.svg'">
</rap-breadcrumb-view-new> -->

<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>

    <div>
        <label class="non-clickable-label">{{title}}</label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="non-clickable-label" style="text-decoration: none;">Songs</label>
    </div>

</div>

<div class="section-body">
    <div class="section-title">Songs</div>
    <button type="button" class="btn btn-dark add-song-btn" (click)="createSongEmitter.emit()"
        style="margin-bottom: 20px;">
        Add New
    </button>
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>

</div>

<rap-search-input [(searchValue)]="filters.search" (search)="getSearchResults($event)"
    (clear)="onClearSearch()"></rap-search-input>
<div style="padding: 0px 10px;">
    <ng-container *ngIf="songsData?.length > 0">
        <div class="custom-container">
            <div class="songs-table" *ngIf="songsData?.length > 0">
                <table class="table rounded-4 overflow-hidden">
                    <thead>
                        <tr>
                            <th scope="col">Song title</th>
                            <th scope="col">Artist</th>
                            <th scope="col">Album</th>
                            <th scope="col">Tags</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let song of paginatedSongs">
                            <td scope="row">{{ song?.title }}</td>
                            <td scope="row">
                                {{ song?.artist.artist }}
                            </td>
                            <td scope="row">{{ song?.album.album }}</td>
                            <td scope="row">{{ song?.tags }}</td>
                            <td>
                                <button type="button" class="btn btn-light rect-secondary-btn" (click)="editSong(song)">
                                    Edit
                                </button>
                                <button type="button" class="btn btn-dark rect-primary-btn"
                                    (click)="deleteSong(song.songid)">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="pagination">
                    <rap-pagination [collectionSize]="pagesCount" [page]="page" [pageSize]="pageSize"
                        (onPageChange)="onPageChange($event)"></rap-pagination>
                </div>
            </div>
        </div>

    </ng-container>
</div>

<rap-no-data [data]="songsData" [isLoading]="isLoading" [filters]="filters" [message]="'No songs found'"
    [view]=""></rap-no-data>