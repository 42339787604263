import { Component, Input } from '@angular/core';

@Component({
  selector: 'rap-breadcrumb-view-new',
  templateUrl: './breadcrumb-view-new.component.html',
  styleUrls: ['./breadcrumb-view-new.component.scss']
})
export class BreadcrumbViewNewComponent {
  @Input() routerLink: string | null = null; // For the clickable label
  @Input() clickableLabel: string = '';      // Text for the clickable label
  @Input() clickableIcon: string | null = null; // Icon for the clickable label

  @Input() title: string = '';               // Non-clickable label in the center

  @Input() nonClickableLabel: string = '';   // Non-clickable label text
  @Input() nonClickableIcon: string | null = null; // Icon for the non-clickable label
}
