import { Component, Input } from '@angular/core';
import { CardsService } from '../cards.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfilePhotoModalComponent } from 'src/app/user-profile/profile-photo-modal/profile-photo-modal.component';
import { QuillModule } from 'ngx-quill'
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'rap-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrls: ['./edit-card.component.scss']
})
export class EditCardComponent {
  cardItem: any;
  isLoading = false;
  id: string;
  editorContent = '';
  imagePath: any;

  form = new FormGroup({
    content: new FormControl(this.editorContent),
  });


  constructor(

    private cardService: CardsService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router,
    private loaderService: AppService, private modalService: NgbModal) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });


  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id)
    this.isLoading = true;
    // this.baseTemplateService.showHideSearch(false);
    this.cardService.getCard(this.id).subscribe((result) => {
      console.log(result)
      this.cardItem = result;
      this.editorContent = result.text;
      this.isLoading = false
      console.log(result.path)
      this.imagePath = result.path

    });
  }

  onUploadImage() {
    const modalRef = this.modalService.open(ProfilePhotoModalComponent);
    modalRef.result
      .then((data) => {
        console.log(data);
        this.isLoading = true;
        data.forEach((value, key) => {
          console.log(key, value);
        });
        this.cardService.cardsImage(data, this.id).subscribe((result) => {
          console.log(result)
          this.isLoading = false;
        });

        this.isLoading = false;


      })
      .catch((error) => {
        this.isLoading = false;
      });
  }

  onReset() {
    this.form.reset()
  }

  onCancel() {
    this.router.navigateByUrl(`/dashboard/cards`,
    );
  }

  onSave() {
    console.log(this.editorContent)
    this.isLoading = true;
    var card: any = this.cardItem;
    var data =
    {
      "id": card.id ?? "",
      "data": {
        "image": null, "text": this.editorContent,
        "searchWord": card.searchWord, "chartType": card.chartData,
      }
    }
    this.cardService.putCard(this.id, data).subscribe((result) => {
      console.log(result)
      this.isLoading = false
      this.router.navigateByUrl(`/dashboard/cards`,
      );
    });
  }

  getChartOptions(chartData: any) {
    console.log(chartData.chartType)
    if (!chartData.data || !chartData.data[0] || chartData.data[0].data == null) {
      console.log('Invalid data structure:', chartData);
      return {}; // Return an empty object or handle error
    }

    // return {};

    return {
      title: {
        text: ""
      },
      axisY: {
        title: 'Word Count',
        gridThickness: 1,
        tickLength: 10,
      },
      axisX: {
        title: 'Artist'
      },
      data: [{
        type: 'column',
        maxBarThickness: 30,
        dataPoints: chartData.data[0].data.map((item: any) => ({
          label: item.artist,
          y: item.count
        }))
      }]
    };
  }

  getChartOptionsTimeLine(chart: any) {
    return {
      theme: "light2",
      animationEnabled: true,
      zoomEnabled: true,
      title: {
        text: ""
      },
      axisX: {
        title: "Year",
        valueFormatString: "YYYY",
        interval: 1,
      },
      axisY: {
        title: "Count",
      },
      data: [
        {
          type: "line", // Timeline is best represented as a line chart
          xValueFormatString: "YYYY",
          markerType: "circle", // Display dots on the line
          markerSize: 6, // Set the size of the dots
          markerColor: "black", // Change the color of the dots
          lineColor: "black", // Cu
          fillOpacity: 0.5, // Adds a semi-transparent fill color below the line
          color: "white",
          dataPoints: chart.data[0].data.map(d => ({ x: new Date(d.year, 0), y: d.count })).sort((a, b) => a.x.getTime() - b.x.getTime()) // Convert year to date
        }
      ]
    };
  }
}
