import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PasswordInputComponent } from './form-fields/password-input/password-input.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { NgbPagination, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from './component/delete-modal/delete-modal.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NoDataComponent } from './no-data/no-data.component';
import { BreadcrumbDefaultComponent } from './breadcrumb-default/breadcrumb-default.component';
import { BreadcrumbEditNewComponent } from './breadcrumb-edit-new/breadcrumb-edit-new.component';
import { BreadcrumbViewNewComponent } from './breadcrumb-view-new/breadcrumb-view-new.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RectButtonLoaderComponent } from './rect-button-loader/rect-button-loader.component';
import { TermsPrivacyComponent } from './terms-privacy/terms-privacy.component';
import { LinkDividerComponent } from './link-divider/link-divider.component';

@NgModule({
  declarations: [PasswordInputComponent, PaginationComponent, DeleteModalComponent, SpinnerComponent, PrivacyPolicyComponent, NoDataComponent, BreadcrumbDefaultComponent, BreadcrumbEditNewComponent, BreadcrumbViewNewComponent, CheckboxComponent, RectButtonLoaderComponent, TermsPrivacyComponent, LinkDividerComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbPagination, NgbPaginationModule, RouterModule],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordInputComponent,
    PaginationComponent,
    SpinnerComponent,
    PrivacyPolicyComponent,
    NoDataComponent,
    BreadcrumbDefaultComponent,
    BreadcrumbEditNewComponent,
    BreadcrumbViewNewComponent,
    CheckboxComponent,
    RectButtonLoaderComponent, TermsPrivacyComponent,
    LinkDividerComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
