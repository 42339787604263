import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OnboardingComponent } from './onboarding.component';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { OnboardingRoutes } from './onboarding.routes';
import { CreateAccountComponent } from '../create-account/create-account.component';
import { OnboardingPresenter } from './onboarding.presenter';
import { OnboardingContainerComponent } from './onboarding.container.component';
import { Step1ContainerComponent } from './step1/step1.container.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler';
import { Step5Component } from './step5/step5.component';
import { SharedModule } from '../shared/shared.module';
@NgModule({
  declarations: [
    OnboardingComponent,
    OnboardingContainerComponent,
    Step1Component,
    Step1ContainerComponent,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(OnboardingRoutes),
    SharedModule,
  ],
  providers: [OnboardingPresenter],
})
export class OnboardingModule { }
