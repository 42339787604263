import { Component, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UserFacade } from '../store/user/facade/user.facade';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordResetSuccessModalComponent } from './password-reset-success-modal/password-reset-success-modal.component';

@Component({
  selector: 'app-password-help',
  templateUrl: './password-help.component.html',
  styleUrls: ['./password-help.component.scss'],
})
export class PasswordHelpComponent implements OnDestroy {
  authError$ = this.userFacade.authError$;
  pwdResetCodeSent: boolean = true;
  pwdUpdateSubmitted: boolean = false;
  passwordHelpForm: FormGroup;
  title: string = 'Need password help?';
  subTitle: string =
    'Type in the email associated with your account and we’ll send you an email to reset it.';
  submitted: boolean = false;
  error: string;
  isValidEmail = false;
  isLoading = false;

  constructor(
    private router: Router,
    private userFacade: UserFacade,
    private modalService: NgbModal,
  ) {
    this.passwordHelpForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      code: new FormControl(null),
      password: new FormControl(null),
      confirmPassword: new FormControl(null),
    });

    this.userFacade.passwordResetSuccess$.subscribe((x) => { });
  }

  ngOnInit() {
    this.pwdResetCodeSent = false;
    this.userFacade.pwdResetCodeSent$.subscribe((x) => {
      this.pwdResetCodeSent = x;
      this.isLoading = false;
      if (this.pwdResetCodeSent) {
        this.passwordHelpForm.get('code').setValidators(Validators.required);
        this.passwordHelpForm
          .get('password')
          .setValidators(Validators.required);
        this.passwordHelpForm
          .get('confirmPassword')
          .setValidators(Validators.required);
        this.passwordHelpForm.get('email').clearValidators();
        this.passwordHelpForm.get('code').updateValueAndValidity();
        this.passwordHelpForm.get('email').updateValueAndValidity();
        this.passwordHelpForm.get('password').updateValueAndValidity();
        this.passwordHelpForm.get('confirmPassword').updateValueAndValidity();
      }
    });

    this.userFacade.authError$.subscribe(error => {
      if (error) {
        // this.authError = error;
        console.log('Auth Error:', error);
        this.isLoading = false;
      } else {
        // this.authError = null;
        console.log('Auth Error:', error);
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.userFacade.resetErrorMsg();
  }

  navigateToSignIn(): void {
    this.router.navigate(['/signin']);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.passwordHelpForm.controls;
  }

  onSubmit(isHome: boolean) {
    if (isHome == false) {
      this.router.navigate(['/signin']);
    } else {
      !this.pwdResetCodeSent
        ? (this.submitted = true)
        : (this.pwdUpdateSubmitted = true);

      if (!this.passwordHelpForm.valid) {
        console.log("jjj")
        this.isValidEmail = false;
        return;
      }
      this.isLoading = true;
      this.isValidEmail = true;
      let formValue = this.passwordHelpForm.value;

      if (formValue.password != formValue.confirmPassword) {
        this.error = 'Passwords are not equal';
        return;
      }

      if (!this.pwdResetCodeSent) {
        this.userFacade.sendPwdResetCode(this.passwordHelpForm.value.email);
      } else {
        console.log(this.passwordHelpForm.value)
        this.userFacade.resetPassword(this.passwordHelpForm.value);
      }
    }


  }

  hasEmailError(): boolean {
    const emailErrors = this.passwordHelpForm.controls['email'].errors;
    console.log(!!emailErrors && !!emailErrors['email'])
    return !!emailErrors && !!emailErrors['email'];
  }

  clearInput(input: string): void {
    console.log("ws");
    this.passwordHelpForm.controls['email'].setValue('');
    this.submitted = false;
  }

  open() {
    const modalRef = this.modalService.open(PasswordResetSuccessModalComponent);
    modalRef.componentInstance.modalTitle = 'Success';
    modalRef.componentInstance.modalBodyText = 'Your password has been reset';
    modalRef.componentInstance.moveToSignin = true;
  }

  ngDestroy() {
    this.pwdResetCodeSent = false;
    this.isLoading = false;
  }
}
