<rap-breadcrumb-edit-new [exploreLabel]="'Rap Almanac'" [exploreLink]="'/dashboard/explore'"
    [exploreImage]="'../assets/images/filled.svg'" [subLabel]="'Users'" [subLink]="'/dashboard/users'"
    [subId]="{ id: editableUser?.id }" [isNew]="false" [isView]="false" [filledImage]="'../assets/images/filled.svg'"
    [title]=""></rap-breadcrumb-edit-new>

<div style="padding: 0px 10px;">
    <div class="section-title">
        {{ title }}
    </div>
    <div class="error-container" *ngIf="errorMessage">
        {{ errorMessage }}
    </div>
    <div class="card">
        <div class="card-body">
            <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
            <form [formGroup]="userForm" (ngSubmit)="addUpdateUser()">
                <div class="form-group">
                    <label for="firstName">First Name</label>
                    <div class="input-container">
                        <input type="text" class="search-input" [class.submitted]="submitted"
                            formControlName="firstName" />
                    </div>
                    <div *ngIf="submitted && f['firstName'].errors" class="rap-error-text">
                        <span *ngIf="f['firstName'].errors?.['required']">First name is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="studentRoster">Student Roster</label>
                    <!-- <div class="input-container">
                        <select class="form-control" formControlName="studentRoster" [class.submitted]="submitted">
                            <option value="" disabled selected>Select Roster</option>
                            <option *ngFor="let roster of rosters" [value]="roster.id">
                                {{ roster.label }}
                            </option>
                        </select>
                    </div> -->
                    <div class="multi-dropdown">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="settings" [data]="rosters"
                            [(ngModel)]="userForm.value.studentRoster" [formControl]="userForm.get('studentRoster')">
                        </ng-multiselect-dropdown>

                    </div>
                    <!-- <div *ngIf="submitted && f['studentRoster'].errors" class="rap-error-text">
                        <span *ngIf="f['studentRoster'].errors?.['required']">Student roster is required</span>
                    </div> -->
                </div>
                <div class="form-group">
                    <label for="lastame">Last Name</label>
                    <div class="input-container">
                        <input type="text" class="search-input" [class.submitted]="submitted"
                            formControlName="lastName" />
                    </div>
                    <div *ngIf="submitted && f['lastName'].errors" class="rap-error-text">
                        <span *ngIf="f['lastName'].errors?.['required']">Last name is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="firstName">User Name</label>
                    <div class="input-container">
                        <input type="text" class="search-input" [class.submitted]="submitted"
                            formControlName="userName" />
                    </div>
                    <div *ngIf="submitted && f['userName'].errors" class="rap-error-text">
                        <span *ngIf="f['userName'].errors?.['required']">User name is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <div class="input-container">
                        <input type="email" class="search-input" [class.submitted]="submitted"
                            [readonly]="editableUser !== null ? true : false" formControlName="email" />
                    </div>
                    <div *ngIf="submitted && f['email'].errors" class="rap-error-text">
                        <span *ngIf="f['email'].errors?.['required']">Email is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="role">Role</label>
                    <!-- <div class="input-container">
                        <select class="form-control" formControlName="role" [class.submitted]="submitted">
                            <option value="" disabled selected>Select Role</option>
                            <option *ngFor="let role of roles" [value]="role.id">
                                {{ role.label }}
                            </option>
                        </select>
                    </div> -->
                    <div class="multi-dropdown">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="rolesSettings" [data]="roles"
                            [(ngModel)]="userForm.value.role" [formControl]="userForm.get('role')" class="search-input">
                        </ng-multiselect-dropdown>

                    </div>
                    <div *ngIf="submitted && f['role'].errors" class="rap-error-text">
                        <span *ngIf="f['role'].errors?.['required']">Role is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="active">Active</label>
                    <div class="checkbox-container">
                        <input class="custom-checkbox" formControlName="active" type="checkbox" value=""
                            id="flexCheckDefault" />
                    </div>
                </div>
                <div class="form-group" style="margin-top: 10px;">
                    <label for="allowMapperDelight">Allow Mapper Delight</label>
                    <div class="checkbox-container">
                        <input class="custom-checkbox" formControlName="allowMapperDelight" type="checkbox" value=""
                            id="flexCheckDefault" />
                    </div>
                </div>

                <!-- <label for="active">Active</label>
                <form [formGroup]="f">
                    <rap-checkbox control="f['active']" id="active" [isSubmitted]="submitted"></rap-checkbox>
                </form>

                <label for="allowMapperDelight" style="margin-top: 10px;">Allow Mapper Delight</label>
                <form [formGroup]="f">
                    <rap-checkbox [control]="f['allowMapperDelight']" id="allowMapperDelight"
                        [isSubmitted]="submitted"></rap-checkbox>
                </form> -->

                <div class="action-btns">
                    <rap-rect-primary [label]="'Save'" [btnClass]="'btn btn-dark rect-primary-btn1'"
                        (clickEvent)="addUpdateUser()"></rap-rect-primary>
                    <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn1'"
                        (clickEvent)="onReset()"></rap-rect-primary>
                    <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn1'"
                        (clickEvent)="Cancel()"></rap-rect-primary>

                </div>
            </form>
        </div>
    </div>
</div>