import { Component, SimpleChanges } from '@angular/core';
import { AlbumRow, AlbumRowArtist, AlbumsData } from '../models/albums/albums.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AlbumsService } from './albums.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';

@Component({
  selector: 'rap-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss']
})
export class AlbumsComponent {
  isLoading: boolean = false;
  albums: AlbumsData;
  albumsData: AlbumRow[] = [];
  page = 1;
  pageSize = 10;
  filters: any = {
    search: ""
  };
  pagesCount = 0;
  title = "Database";
  isContributions: boolean = false;
  constructor(private router: Router, private albumService: AlbumsService,
    private route: ActivatedRoute, private modalService: NgbModal,
    private toastr: ToastrService, private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      // if (i)
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.data.subscribe(data => {
      this.isContributions = data["isContributions"];
      console.log(this.isContributions);
      if (this.isContributions) {
        this.title = "Contributions";
      }
    })
    this.getAllAlbms("", this.page);
  }

  getAllAlbms(search: string, page: number) {
    this.albumService.getAllAlbums(search, page, this.isContributions).subscribe((result) => {
      this.isLoading = false;
      this.albums = result;
      this.albumsData = this.albums.rows;
      if (this.page == 1)
        this.pagesCount = result.count;

    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['albums']) {
    }
  }

  get paginatedAlbums(): AlbumRow[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    // console.log(start)
    // console.log(end)
    // console.log(this.songsData.slice(start, end))
    return this.albumsData;
  }

  onPageChange(page: number) {
    this.page = page;
    console.log(this.page)
    this.isLoading = true;
    this.getAllAlbms(this.filters.search, this.page);
  }

  createAlbum() {
    var url = "/dashboard/database/albums/add";
    if (this.isContributions) {
      url = "/dashboard/contributions/albums/add";
    }
    this.router.navigateByUrl(url, { skipLocationChange: true, state: { isContributions: this.isContributions } }
    );
  }

  getSearchResults(search: string) {
    this.filters.search = search
    this.isLoading = true;
    this.getAllAlbms(this.filters.search, this.page);
  }

  editAlbum(album: any) {
    // var url = "/dashboard/database/albums/edit";
    // if (this.isContributions) {
    //   url = "/dashboard/contributions/albums/" + album.artistid + "/edit";
    // }
    // this.router.navigateByUrl(url, { skipLocationChange: true, state: { isContributions: this.isContributions, id: album.albumid } }
    // );

    this.toastr.success('Feature Coming Soon!!!', '', {
      positionClass: 'toast-top-center',
      closeButton: false,
    });
  }

  deleteAlbum(id: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = id;
    modalRef.result
      .then((id) => {
        this.isLoading = true;
        this.albumService.deleteAlbum(id).subscribe((result) => {
          this.isLoading = false;
          this.getSearchResults("");
        });
      })
      .catch((error) => {
        this.isLoading = false;
      });
  }
  onClearSearch() {
    this.filters.search = "";
    this.getSearchResults("")
  }
}
