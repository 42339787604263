<div class="modal-header" style="border-bottom: none;">
    <h4 class="modal-title">Change your password</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body no-padding">
    <p class="card-text">Upon your request, add a new password different from the last</p>
    <form [formGroup]="passwordHelpForm" (ngSubmit)="onSubmit()">
        <div class="error-container" *ngIf="error">
            {{ error }}
        </div>
        <div class="form-group">
            <label for="code" style="padding-bottom: 0; margin-bottom: 0;">Enter code</label>
            <div class="input-container">
                <input type="text" class="search-input" placeholder="Enter code" formControlName="code" />
                <i class="auth-icons bi bi-shield-fill"></i>
            </div>
        </div>

        <div class="form-group">
            <label for="password" style="padding-bottom: 0; margin-bottom: 0;">New password</label>
            <div class="input-container">
                <input id="password" formControlName="password" type="{{ fieldTextType ? 'text' : 'password' }}"
                    class="search-input" placeholder="Enter new password" />
                <div class="auth-icons">
                    <!-- <i class="bi bi-shield-fill"></i> -->
                    <i (click)="toggleFieldTextType()"
                        [ngClass]="fieldTextType ? 'bi-eye-fill' : 'bi-eye-slash-fill'"></i>
                </div>

            </div>

            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="error">
                <small *ngIf="password.errors?.required" class="error">Password is required.</small>
                <small *ngIf="password.errors?.minlength" class="error">Password must be at least 8 characters
                    long.</small>
            </div>
        </div>

        <div class="form-group">
            <label for="confirmPassword" style="padding-bottom: 0; margin-bottom: 0;">Re-enter new password</label>
            <div class="input-container">
                <input id="confirmPassword" formControlName="confirmPassword" type="password"
                    type="{{ fieldTextType1 ? 'text' : 'password' }}" class="search-input"
                    placeholder="Re-enter your password" />
                <div class="auth-icons">
                    <!-- <i class="bi bi-shield-fill"></i> -->
                    <i (click)="toggleFieldTextType1()"
                        [ngClass]="fieldTextType1 ? 'bi-eye-fill' : 'bi-eye-slash-fill'"></i>
                </div>
            </div>

            <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)" class="error">
                <small *ngIf="confirmPassword.errors?.required">Confirm Password is required.</small>
                <small *ngIf="confirmPassword.errors?.mismatch">Passwords do not match.</small>
            </div>
        </div>

        <button type="submit" class="rect-primary-btn1"
            style="margin-bottom:20px; width: 100%; background-color: black; color: white;">

            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!isLoading">Update</span>
        </button>
        <!-- <div class="info">If you don’t require password help, you can</div> -->


    </form>
</div>