<div>

  <div class="plan-container">
    <img src="../assets/images/arrow_left.svg" style="margin-bottom: 20px;" (click)="goBack()" />
  </div>

  <div class="header">
    <pre>{{title}}</pre>
    <p>{{subTitle}}</p>
  </div>

  <div class="plan-container">
    <ng-container *ngFor="let plan of choosePlans;  let i = index">
      <div class="option1" (click)="selectPlan(plan)" [class.selected]="plan === selectedPlan">
        <div>
          <h3>{{ plan.price }}
            <h6 *ngIf="i !== 0">/month</h6>
          </h3>
        </div>
        <div>
          {{ plan.option }}
          <ng-container *ngFor="let op of plan.options">
            <div class="icon-text-container ">
              <i class="bi bi-check-circle"></i>
              <span>{{ op }}</span>
            </div>
          </ng-container>
        </div>
      </div>

    </ng-container>
  </div>


  <div class="header" style="padding-top: 30px;">
    <rap-rect-button-loader [isLoading]="isLoading && !isSkip" [disabled]="false" buttonClass="btn btn-dark btn-signin"
      style="padding-top: 10px;" type="submit" (clickEvent)="onContinue()">
      {{continueTitle}}
    </rap-rect-button-loader>

    <rap-rect-button-loader [isLoading]=" isLoading && isSkip" [disabled]="false" buttonClass="btn btn-light btn-create"
      type="submit" (clickEvent)="onSkip()" style="padding-top: 10px;">
      {{skipTitle}}
    </rap-rect-button-loader>
  </div>