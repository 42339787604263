<div class="sign-in">
  <div class="left">

    <div class="container">
      <h1>{{ title }}</h1>
      <p>{{ subTitle }}</p>
      <form [formGroup]="authForm" (ngSubmit)="login()">
        <div class="error-container" *ngIf="authError$ | async">
          {{ authError$ | async }}
        </div>

        <div class="form-group">
          <rap-spinner class="loader-view" *ngIf="isLoading" [showSpinner]="false"></rap-spinner>
          <label for="email">Email address</label>
          <div class="input-container">
            <input type="email" class="search-input" [class.submitted]="submitted" formControlName="email"
              placeholder="Enter an email address" />
            <div class="auth-icons">
              <i *ngIf="submitted && hasEmailError()" class="bi bi-x-circle-fill" style="margin-left: 10px;"
                (click)="clearInput('email')"></i>
              <i class="bi bi-envelope-fill" style="margin-left: 10px;"></i>
              <i *ngIf="submitted && !hasEmailError()" class="bi bi-check-circle-fill"
                style="margin-left: 10px; color: green;"></i>
            </div>

          </div>
          <div *ngIf="submitted && f['email'].errors" class="rap-error-text">
            <span *ngIf="f['email'].errors?.['required']">Email is required</span>
            <span *ngIf="authForm.controls.email.errors.email">Please enter a valid email address</span>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <app-password-input [submitted]="submitted" [formControl]="f['password']"></app-password-input>
        </div>

        <p class="help-text" (click)="navigateToPasswordHelp()">
          Need help signing in?
        </p>

        <rap-rect-button-loader [isLoading]="isLoading" [disabled]="false" buttonClass="btn btn-dark btn-signin"
          type="submit" (clickEvent)="login()">
          Sign In
        </rap-rect-button-loader>

        <rap-link-divider></rap-link-divider>

        <rap-rect-button-loader [isLoading]="false" [disabled]="false" buttonClass="btn btn-light btn-create"
          type="submit" (clickEvent)="navigateToCreateAccount()">
          Create an account
        </rap-rect-button-loader>

        <!-- <button type="button" class="btn-create" (click)="navigateToCreateVILSAccount()">
          Login as VILS?
        </button> -->

      </form>
      <rap-terms-privacy></rap-terms-privacy>

    </div>
  </div>
  <div class="right"></div>
</div>