import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SongsFacade } from '../store/songs.facade';
import { AlbumsService } from 'src/app/albums/albums.service';
import { AuthService } from 'src/app/services/auth.service';
import { ArtistsService } from 'src/app/artists/artists.service';

@Component({
  selector: 'rap-add-edit-song',
  templateUrl: './add-edit-song.container.component.html',
})
export class AddEditSongContainerComponent {
  // albums$ = this.songsFacade.albums$;
  // artists$ = this.songsFacade.artists$;
  producers$ = this.songsFacade.producers$;
  errorMessage$ = this.songsFacade.errorMessage$;
  title = 'Add Song';
  isContributions = false;
  albums: any;
  artists: any;
  songId: any;
  constructor(
    private songsFacade: SongsFacade,
    private route: ActivatedRoute,
    private albumService: AlbumsService,
    private router: Router,
    private authService: AuthService,
    private artistService: ArtistsService
  ) {
    // this.songsFacade.getAlbums();
    // this.songsFacade.getArtists();
    this.songsFacade.getProducers();
    console.log(this.authService.getUserId());
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.title = 'Edit Song';
        this.songId = params['id'];
        this.songsFacade.getSongById(params['id']);
      }
    });

    const navigation = this.router.getCurrentNavigation();
    console.log(navigation?.extras.state)
    if (navigation?.extras.state) {
      this.isContributions = navigation.extras.state['isContributions'];
      console.log(this.isContributions)
      this.albumService.getAlbumsList(this.authService.getUserId(), this.isContributions).subscribe((result) => {
        this.albums = result;
        console.log(result)
      });

      this.artistService.getAutoComplete(this.authService.getUserId(), this.isContributions).subscribe((result) => {
        this.artists = result;
        console.log(result)
      });
    }
  }

  ngOnDestroy() {
    this.songsFacade.clearErrorMessage();
  }

  addSong(song) {
    this.songsFacade.addSong(song);
  }

  editSong(song) {
    // this.songsFacade.updateSong(song);
  }
}
