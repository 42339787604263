import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rap-rect-button-loader',
  templateUrl: './rect-button-loader.component.html',
  styleUrls: ['./rect-button-loader.component.scss']
})
export class RectButtonLoaderComponent {
  @Input() isLoading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() buttonClass: string = '';
  @Input() type: string = 'button';
  @Output() clickEvent = new EventEmitter<any>();

  onClick(event: Event): void {
    this.clickEvent.emit(event);
  }
}
