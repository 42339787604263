<div class="section-body">
  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <div class="section-title">Students</div>

</div>

<rap-search-input [(searchValue)]="filters.search" (search)="getSearchResults($event)"
  (clear)="onClearSearch()"></rap-search-input>
<div style="padding: 0px 10px;">
  <ng-container *ngIf="studentsData?.length > 0">
    <div class="custom-container">
      <div class="rosters-table">
        <table class="table rounded-4 overflow-hidden">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Clear Audio</th>
              <th scope="col">View Progress</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let student of paginatedStudents">
              <td scope="row">{{ student?.firstName }}</td>
              <td scope="row">{{ student?.forceCleanAudio }}</td>
              <td scope="row">

                <a class="user-details-link" (click)="onProgress(student)">
                  View Progress
                </a>
              </td>
              <td>
                <rap-rect-primary [label]="'Edit'" [btnClass]="'btn btn-light rect-secondary-btn'"
                  (clickEvent)="manageStudent(student)"></rap-rect-primary>
                <rap-rect-primary [label]="'Delete'" [btnClass]="'btn btn-dark rect-primary-btn'"
                  (clickEvent)="deleteStudent(student.id)"></rap-rect-primary>

              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div class="pagination">
      <rap-pagination [collectionSize]="studentsData?.length" [page]="page" [pageSize]="pageSize"
        (onPageChange)="onPageChange($event)"></rap-pagination>
    </div>

  </ng-container>
</div>

<div class="button-row" style="padding: 0px 20px;">

  <rap-rect-primary [label]="'Add New'" [btnClass]="'btn btn-dark rect-primary-btn'"
    (clickEvent)="addStudent()"></rap-rect-primary>
  <div class="right-buttons">
    <rap-rect-primary [label]="'Help'" [btnClass]="'btn btn-dark rect-primary-btn'"
      (clickEvent)="onHelp()"></rap-rect-primary>
    <rap-rect-primary [label]="'Export to CSV'" [btnClass]="'btn btn-dark rect-primary-btn'"
      (clickEvent)="exportCSV()"></rap-rect-primary>
  </div>
</div>

<rap-no-data [data]="studentsData" [isLoading]="isLoading" [filters]="filters"
  [message]="'No students found'"></rap-no-data>