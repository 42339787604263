<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>

    <div>
        <label class="non-clickable-label">{{subTitle}}</label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="clickable-label" (click)="onCancel()">Producers</label>
        <!-- <img src="../assets/images/filled.svg" /> -->
    </div>
    <!-- <label class="clickable-label" routerLink="/dashboard/roles">Roles</label> -->
    <!-- <div *ngIf="isNew === false">
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label">{{ artistId }}</label>
    </div> -->
    <div>
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label" style="text-decoration: none;">New</label>
    </div>
</div>

<div class="producers-section">
    <div class="section-title">
        {{ title }}
    </div>
    <div class="error-container" *ngIf="errorMessage">
        {{ errorMessage }}
    </div>
    <div class="card">
        <div class="card-body">
            <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
            <form [formGroup]="producerForm" (ngSubmit)="addUpdateProducer()">
                <div class="form-group">
                    <label for="name">Name</label>
                    <div class="input-container">
                        <input type="text" class="search-input" [class.submitted]="submitted" formControlName="name" />
                    </div>
                    <div *ngIf="submitted && f['name'].errors" class="rap-error-text">
                        <span *ngIf="f['name'].errors?.['required']">Name is required</span>
                    </div>
                </div>
                <div class="action-btns">
                    <rap-rect-primary [label]="'Save'" [btnClass]="'btn btn-dark rect-primary-btn1'"
                        (clickEvent)="addUpdateProducer()"></rap-rect-primary>
                    <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn1'"
                        (clickEvent)="onReset()"></rap-rect-primary>
                    <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn1'"
                        (clickEvent)="onCancel()"></rap-rect-primary>
                </div>
            </form>
        </div>
    </div>
</div>