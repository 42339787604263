<!-- <div class="section-body1">
    <div class="search-container">
        <img src="../assets/images/search.svg" class="search-icon" />
        <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="searchValue"
            (keydown.enter)="onSearch()" />
        <div class="auth-icons">
            <i class="bi bi-x-circle-fill" style="margin-left: 10px;" *ngIf="searchValue !== ''"
                (click)="clearSearch()"></i>
        </div>
    </div>
</div> -->

<div class="section-body1">
    <div class="search-container">
        <div class="auth-icons">
            <img src="../assets/images/search.svg" class="search-icon" />
        </div>
        <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="searchValue"
            (keydown.enter)="onSearch()" />
        <div class="auth-icons">
            <i class="bi bi-x-circle-fill" *ngIf="searchValue !== ''" (click)="clearSearch()"></i>
        </div>
    </div>
</div>