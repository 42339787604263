<rap-breadcrumb-edit-new [exploreLabel]="'Rap Almanac'" [exploreLink]="'/dashboard/explore'"
  [exploreImage]="'../assets/images/filled.svg'" [subLabel]="'Roles'" [subLink]="'/dashboard/roles'"
  [subId]="{ id: editableRole?.id  }" [isNew]="isNew" [isView]="false" [filledImage]="'../assets/images/filled.svg'"
  [title]=""></rap-breadcrumb-edit-new>

<div style="padding: 0px 10px;">
  <div class="section-title">
    {{ title }}
  </div>
  <div class="error-container" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
  <div class="card">
    <div class="card-body">
      <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
      <form [formGroup]="roleForm" (ngSubmit)="addUpdateRole()">
        <div class="form-group">
          <label for="email">Name</label>
          <div class="input-container">
            <input type="text" class="search-input" [class.submitted]="submitted" formControlName="name" />
          </div>
          <div *ngIf="submitted && f['name'].errors" class="rap-error-text">
            <span *ngIf="f['name'].errors?.['required']">Name is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Permissions</label>
          <div class="multi-dropdown">
            <ng-multiselect-dropdown #multiSelect [placeholder]="'Select Permissions'" [settings]="settings"
              [data]="permissions" [(ngModel)]="roleForm.value.permissions" [formControl]="roleForm.get('permissions')">
            </ng-multiselect-dropdown>
          </div>
          <div *ngIf="submitted && f['permissions'].errors" class="rap-error-text">
            <span *ngIf="f['permissions'].errors?.['required']">Permissions is required</span>
          </div>
        </div>



        <div class="action-btns">
          <rap-rect-primary [label]="'Save'" [btnClass]="'btn btn-dark rect-primary-btn1'"
            (clickEvent)="addUpdateRole()"></rap-rect-primary>
          <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn1'"
            (clickEvent)="onReset()"></rap-rect-primary>
          <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn1'"
            (clickEvent)="Cancel()"></rap-rect-primary>

        </div>
      </form>
    </div>
  </div>
</div>