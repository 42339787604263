<div class="sign-in">
    <div class="left">

        <div class="container">
            <h1>VILS Login</h1>
            <p>Use instructor email and passcode to sign in.</p>

            <form [formGroup]="authForm" (ngSubmit)="login()">


                <div class="form-group">
                    <rap-spinner class="loader-view" *ngIf="isLoading" [showSpinner]="false"></rap-spinner>
                    <label for="email">Email address</label>
                    <div class="input-container">
                        <input type="email" class="search-input" [class.submitted]="submitted" formControlName="email"
                            placeholder="Enter an email address" />
                        <div class="auth-icons">
                            <i *ngIf="submitted && hasEmailError()" class="bi bi-x-circle-fill"
                                style="margin-left: 10px;" (click)="clearInput('email')"></i>
                            <i class="bi bi-envelope-fill" style="margin-left: 10px;"></i>
                            <i *ngIf="submitted && !hasEmailError()" class="bi bi-check-circle-fill"
                                style="margin-left: 10px; color: green;"></i>
                        </div>

                    </div>
                    <div *ngIf="submitted && f['email'].errors" class="rap-error-text">
                        <span *ngIf="f['email'].errors?.['required']">Email is required</span>
                        <span *ngIf="authForm.controls.email.errors.email">Please enter a valid email address</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Passcode</label>
                    <div class="input-container">
                        <input [type]="'password'" class="search-input" [class.submitted]="submitted"
                            formControlName="password" placeholder="Passcode" />


                        <div class="auth-icons">
                            <i *ngIf="submitted && hasPasswordError1()" class="bi bi-x-circle-fill"
                                style="margin-left: 10px;" (click)="clearInput('password')"></i>

                            <i *ngIf="submitted && !hasPasswordError1() " class="bi bi-check-circle-fill"
                                style="margin-left: 10px; color: green;"></i>
                        </div>
                    </div>
                    <!-- <app-password-input [submitted]="submitted" [formControl]="f['password']"></app-password-input> -->

                </div>

                <p class="help-text" (click)="navigateToForgotPassword()">
                    Forgot Password?
                </p>
                <button type="submit" class="btn-signin">
                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    <span *ngIf="!isLoading">Login</span>
                </button>
                <div class="links">
                    <div></div>
                    <span>Or</span>
                    <div></div>
                </div>
                <button type="button" class="btn-create" (click)="navigateToSignin()">
                    Sign in
                </button>
                <p class="terms">
                    By continuing, you agree to our <a [routerLink]="'/privacy-notice'" target="_blank">Terms of
                        Service</a> and
                    <a [routerLink]="'/privacy-notice'" target="_blank">Privacy Policy</a>
                </p>

            </form>
        </div>
    </div>
    <div class="right"></div>
</div>