<h2>Tell us a bit about yourself</h2>
<p>What brings you to Rap Almanac?</p>
<ng-container *ngFor="let option of options">
  <!-- <button class="options" (click)="nextStep(option)">{{ option }}</button> -->
  <div class="options" (click)="nextStep(option)" [class.selected]="option === selectedOption">
    {{ option }}
  </div>
</ng-container>

<rap-rect-button-loader [isLoading]="false" [disabled]="false" buttonClass="btn btn-dark btn-signin"
  style="padding-bottom: 10px;" type="submit" (clickEvent)="onContinue()">
  Continue
</rap-rect-button-loader>
<rap-rect-button-loader [isLoading]="false" [disabled]="false" buttonClass="btn btn-light btn-create" type="submit"
  (clickEvent)="onSkip()">
  Skip
</rap-rect-button-loader>