import { Component } from '@angular/core';

@Component({
  selector: 'rap-terms-privacy',
  templateUrl: './terms-privacy.component.html',
  styleUrls: ['./terms-privacy.component.scss']
})
export class TermsPrivacyComponent {

}
