import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'rap-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() control!: FormControl; // FormControl for the checkbox
  @Input() id: string = 'checkbox'; // Unique ID for the checkbox
  @Input() isSubmitted: boolean = false; // Flag for "submitt

}
