import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rap-rect-primary',
  templateUrl: './rect-primary.component.html',
  styleUrls: ['./rect-primary.component.scss']
})
export class RectPrimaryComponent {
  @Input() label: string = 'Click Me'; // Button text
  @Input() btnClass: string = 'btn btn-primary'; // CSS classes for the button
  @Output() clickEvent = new EventEmitter<any>(); // Event emitter for click events

  ngOnInit(): void {
    console.log("this.isLoading")
  }


  onClick(event: Event): void {
    this.clickEvent.emit(event); // Emit click event
  }
}
