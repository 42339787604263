import { Component } from '@angular/core';

@Component({
  selector: 'rap-link-divider',
  templateUrl: './link-divider.component.html',
  styleUrls: ['./link-divider.component.scss']
})
export class LinkDividerComponent {

}
