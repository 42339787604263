import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Student } from '../models/students/student.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { StudentsService } from './students.service';
import { StudentsHelpModalComponent } from './students-help-modal/students-help-modal.component';

@Component({
  selector: 'rap-students-ui',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent {
  @Input() students: Student[];
  @Output() deleteStudentEmitter = new EventEmitter<string>();
  @Output() manageStudentEmitter = new EventEmitter<Student>();
  @Input() showLoader: boolean;
  page = 1;
  pageSize = 10;
  @Input() rosterId: string;
  isLoading = false;
  searchQuery = "";
  filters: any = {
    search: ""
  };
  // students: any;
  studentsData: any;
  constructor(
    private router: Router,
    private modalService: NgbModal, private loaderService: AppService,
    private toastr: ToastrService, private studentService: StudentsService
  ) {
    // this.loaderService.loading$.subscribe(isLoading => {
    //   console.log("isLoading")
    //   console.log(isLoading)

    //   console.log(this.rosterId)
    //   this.isLoading = isLoading;


    // });
  }

  ngOnInit(): void {
    console.log(this.showLoader)
    // if (this.showLoader == false)
    //   this.isLoading = true;
    console.log(this.isLoading)
    console.log(this.rosterId);
    this.isLoading = true;


  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['students']) {
      this.isLoading = false;
      this.studentsData = this.students;
      // this.students = this.studentsData;

      console.log(this.studentsData)
    }


  }

  get paginatedStudents(): Student[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.studentsData.slice(start, end);
  }

  addStudent() {
    console.log("nb")
    this.router.navigate(['/dashboard/rosters/' + this.rosterId + '/add']);
  }

  onPageChange(page: number) {
    this.page = page;
  }

  deleteStudent(functionId: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.modalTitle = 'Confirm delete';
    modalRef.componentInstance.modalBodyText =
      'Are you sure you want to delete thie item ?';
    modalRef.componentInstance.itemId = functionId;
    modalRef.result
      .then((id) => {
        // this.isLoading = true;
        console.log(id)
        // this.deleteStudentEmitter.emit(id);
        this.studentService.deleteStudents(id, this.rosterId).subscribe((result) => {
          console.log(result)
          this.isLoading = false;
          const itemIndex = this.studentsData.findIndex(item => item.id === id);
          if (itemIndex !== -1) {
            this.studentsData.splice(itemIndex, 1);
          }
          this.toastr.success('Student deleted successfullly', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
        });

      })
      .catch((error) => console.log(error));
  }

  // editStudent(student: Student) {
  //   this.editStudentEmitter.emit(student);
  // }

  getSearchResults(searchValue: string) {
    // this.errorMessage = "";
    this.filters.search = searchValue;
    console.log(this.filters.search);
    if (this.filters.search) {
      this.searchQuery = this.filters.search;
      var localArr = this.students.filter(item =>
        item.userName.toLowerCase().includes(this.filters.search.toLowerCase())
      );
      console.log(localArr);
      this.studentsData = localArr;
    } else {
      this.studentsData = this.students;
    }
  }

  manageStudent(student: Student) {
    console.log(student)
    console.log("000")
    this.manageStudentEmitter.emit(student)
  }

  onProgress(student: Student) {
    this.toastr.success('Feature Coming Soon!!!', '', {
      positionClass: 'toast-top-center',
      closeButton: false,
    });
  }

  data = [];

  isExport = false;
  exportCSV() {
    console.log(this.students.length)
    for (let i = 0; i < this.students.length; i++) {
      const generatedData = {
        id: this.students[i].id,
        name: this.students[i].userName,
        code: this.students[i].studentCode,
      };

      this.data.push(generatedData);
    }

    const csvData = this.convertToCSV(this.data);
    this.downloadCSV(csvData, 'student-data.csv');
  }

  convertToCSV(objArray: any[]): string {
    this.isExport = true;
    const array = [Object.keys(objArray[0])].concat(objArray);
    return array.map(row => Object.values(row).join(',')).join('\n');
  }

  downloadCSV(csvData: string, filename: string): void {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.click();
    this.isExport = false;
  }

  onHelp() {
    console.log("help")
    const modalRef = this.modalService.open(StudentsHelpModalComponent,
      {
        windowClass: "customClass",

      });

    modalRef.result
      .then((id) => {
      })
      .catch((error) => console.log(error));
  }

  onClearSearch() {
    this.getSearchResults("");
  }
}
