<!-- <div class="modal-header">
  <h4 class="modal-title">{{ modalTitle }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="card-text">{{ modalBodyText }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light close-modal-btn" (click)="activeModal.dismiss('Cross click')">
    Cancel
  </button>
  <button type="button" class="btn btn-dark delete-btn" (click)="activeModal.close(itemId)">
    {{capitalizeFirstChar(deleteTitle)}}
  </button>
</div> -->


<div class="modal-container">
  <button class="modal-close" (click)="activeModal.dismiss('Cross click')">&#x2715;</button>
  <h2 class="modal-header">Hello world</h2>
  <p class="modal-body">
    {{ modalBodyText }}
  </p>
  <div class="modal-footer">
    <button class="btn cancel" (click)="activeModal.dismiss('Cross click')">Cancel</button>
    <button class="btn okay" (click)="activeModal.close(itemId)">{{capitalizeFirstChar(deleteTitle)}}</button>
  </div>
</div>