<rap-breadcrumb-edit-new [exploreLabel]="'Rap Almanac'" [exploreLink]="'/dashboard/explore'"
  [exploreImage]="'../assets/images/filled.svg'" [subLabel]="'Rosters'" [subLink]="'/dashboard/rosters'"
  [subId]="{ id: editableRoster?.id }" [isNew]="isNew" [isView]="false" [filledImage]="'../assets/images/filled.svg'"
  [title]=""></rap-breadcrumb-edit-new>

<div style="padding: 0px 10px;">
  <div class="card">
    <div class="card-body">
      <div class="section-title">
        {{ title }}
      </div>
      <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
      <form [formGroup]="rosterForm" (ngSubmit)="addUpdateRoster()">
        <div class="form-group">
          <label for="name">Name</label>
          <div class="input-container">
            <input type="text" class="search-input" [class.submitted]="submitted" formControlName="name" />
          </div>
          <div *ngIf="submitted && f['name'].errors" class="rap-error-text">
            <span *ngIf="f['name'].errors?.['required']">Name is required</span>
          </div>
        </div>

        <div style="display: flex;
  align-items: center; gap: 8px;">
          <form [formGroup]="f">
            <rap-checkbox [control]="f['cleanAudioOnly']" id="cleanAudioOnly" [isSubmitted]="submitted"></rap-checkbox>
          </form>
          <label for="cleanAudioOnly" class="checkbox-label" style="margin-top: 6px;">CLEAN AUDIO ONLY</label>

        </div>

        <div class="action-btns">
          <rap-rect-primary [label]="'Save'" [btnClass]="'btn btn-dark rect-primary-btn1'"
            (clickEvent)="addUpdateRoster()"></rap-rect-primary>
          <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn1'"
            (clickEvent)="onReset()"></rap-rect-primary>
          <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn1'"
            (clickEvent)="Cancel()"></rap-rect-primary>
        </div>
      </form>
    </div>
  </div>
</div>

<rap-students *ngIf="editableRoster" [students]="students" [showLoader]="isLoading"
  [rosterID]="editableRoster.id"></rap-students>