import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rap-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @Input() searchValue: string = ''; // Bind the initial search value
  @Output() search = new EventEmitter<string>(); // Emit search results
  @Output() clear = new EventEmitter<void>(); // Emit clear action

  onSearch(): void {
    console.log(this.searchValue);
    this.search.emit(this.searchValue); // Emit search value when Enter is pressed
  }

  clearSearch(): void {
    this.searchValue = ''; // Clear the input value
    this.clear.emit(); // Emit the clear action
  }
}
