import { Component, Input } from '@angular/core';

@Component({
  selector: 'rap-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent {
  @Input() data: any[] = [];
  @Input() view: any = null;
  @Input() isLoading: boolean = false;
  @Input() filters: { search: string } = { search: '' };
  @Input() message: string = 'No data found';
}
