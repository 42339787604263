import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'rap-breadcrumb-default',
  templateUrl: './breadcrumb-default.component.html',
  styleUrls: ['./breadcrumb-default.component.scss']
})
export class BreadcrumbDefaultComponent {
  @Input() exploreLabel: string = 'Explore';
  @Input() exploreLink: string = '/dashboard/explore';
  @Input() exploreImage: string = '../assets/images/filled.svg';
  @Input() rostersLabel: string = '';

  @Output() rostersClick = new EventEmitter<void>();

  constructor(private router: Router) {

  }
  moveToExplore() {
    console.log("vkjvkj")
    this.router.navigate(['/dashboard/explore']);
  }
  onClick() {
    this.rostersClick.emit();
  }
}
