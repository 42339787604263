<div class="label-container">
    <div *ngIf="routerLink">
        <label class="clickable-label" [routerLink]="routerLink">{{ clickableLabel }}</label>
        <img *ngIf="clickableIcon" [src]="clickableIcon" />
    </div>

    <label>{{ title }}</label>

    <div *ngIf="nonClickableLabel">
        <img *ngIf="nonClickableIcon" [src]="nonClickableIcon" />
        <label class="non-clickable-label" style=" text-decoration: none;">{{ nonClickableLabel }}</label>
    </div>
</div>