import { Component, EventEmitter, Input, Output, SimpleChange } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CreateRosterRequest } from 'src/app/models/rosters/create-roster-request.model';
import { Roster } from 'src/app/models/rosters/rosters.model';
import { UpdateRosterRequest } from 'src/app/models/rosters/update-roster-request.model';
import { Student } from 'src/app/models/students/student.model';

@Component({
  selector: 'rap-add-edit-rosters-ui',
  templateUrl: './add-edit-rosters.component.html',
  styleUrls: ['./add-edit-rosters.component.scss'],
})
export class AddEditRostersComponent {
  @Input() editableRoster: Roster;
  @Input() students: Student[];
  @Input() errorMessage: string;
  @Input() title: string;
  @Output() createNewRosterEmitter = new EventEmitter<CreateRosterRequest>();

  @Output() updateRosterEmitter = new EventEmitter<UpdateRosterRequest>();
  rosterForm: FormGroup;
  submitted: boolean = false;
  isLoading = false;
  isNew = false
  constructor(private formBuilder: FormBuilder, private loaderService: AppService, private router: Router) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
    this.rosterForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      cleanAudioOnly: [false, [Validators.required]],
    });
  }

  ngOnInit() {

    if (this.title == "Add Roster") {
      this.isNew = true;
      this.isLoading = false;
    } else {
      // this.isLoading = true;
    }
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes['editableRoster']?.currentValue) {
      this.isLoading = false;
      this.rosterForm.patchValue({
        name: this.editableRoster.name,
        cleanAudioOnly: this.editableRoster.teacher.forceCleanAudio,
      });
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.rosterForm.controls;
  }

  addUpdateRoster() {
    this.submitted = true;
    console.log(this.rosterForm.value.cleanAudioOnly)
    if (this.rosterForm.valid) {
      if (!this.editableRoster) {
        this.addRoster();
      } else {
        this.updateRoster();
      }
    }
  }

  addRoster() {
    this.isLoading = true;

    let rosterRequest: CreateRosterRequest = {
      data: {
        name: this.rosterForm.value.name,
        forceCleanAudio: this.rosterForm.value.cleanAudioOnly,
      },
    };
    this.createNewRosterEmitter.emit(rosterRequest);
  }

  updateRoster() {
    this.submitted = true;
    let rosterRequest: UpdateRosterRequest = {
      id: this.editableRoster.id,
      data: {
        name: this.rosterForm.value.name,
        forceCleanAudio: this.rosterForm.value.cleanAudioOnly,
      },
    };
    this.updateRosterEmitter.emit(rosterRequest);
  }

  onReset() {
    this.rosterForm.reset();
  }

  Cancel() {
    this.router.navigateByUrl('/dashboard/rosters')
  }

  ngOnDestroy(): void {
    this.editableRoster = null;
    this.isLoading = false;
    this.loaderService.hide();
  }
}
