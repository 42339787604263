import { Routes } from '@angular/router';
import { RolesContainerComponent } from '../roles/roles.container.component';
import { ContributionsContainerComponent } from '../contributions/contributions.container.component';
import { SongsContainerComponent } from '../songs/songs.container.component';
import { ExploreContainerComponent } from '../explore/explore.container.component';
import { RostersContainerComponent } from '../rosters/rosters.container.component';
import { AnalyticsContainerComponent } from '../analytics/analytics.container.component';
import { FunctionsContainerComponent } from '../functions/functions.container.component';
import { AddEditFunctionsContainerComponent } from '../functions/add-edit-function/add-edit-functions.container.component';
import { AddEditRostersContainerComponent } from '../rosters/add-edit-rosters/add-edit-rosters.container.component';
import { AddEditStudentsContainerComponent } from '../students/add-edit-students/add-edit-students.container.component';
import { AddEditRoleContainerComponent } from '../roles/add-edit-role/add-edit-role.container.component';
import { UsersContainerComponent } from '../users/users.container.component';
import { AddEditUserContainerComponent } from '../users/add-edit-user/add-edit-user.container.component';
import { AddEditSongContainerComponent } from '../songs/add-edit-song/add-edit-song.container.component';
import { ProducersContainerComponent } from '../producers/producers.container.component';
import { AddEditProducerContainerComponent } from '../producers/add-edit-producer/add-edit-producer.container.component';
import { CardsComponent } from '../cards/cards.component';
import { ArtistsComponent } from '../artists/artists.component';
import { AlbumsComponent } from '../albums/albums.component';
import { AddEditArtistComponent } from '../artists/add-edit-artist/add-edit-artist.component';
import { AddEditAlbumComponent } from '../albums/add-edit-album/add-edit-album.component';
import { environment } from 'src/environments/environments';
import { AuthGuard } from '../guards/auth.guard';
import { EditCardComponent } from '../cards/edit-card/edit-card.component';
import { TrendingWordsComponent } from './trending-words/trending-words.component';

export const BaseTemplateRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'explore',
  },
  {
    path: 'explore',
    component: ExploreContainerComponent,
  },
  {
    path: 'rosters',
    component: RostersContainerComponent,
  },
  {
    path: 'rosters/new',
    component: AddEditRostersContainerComponent,
  },
  {
    path: 'rosters/:rosterId/edit',
    component: AddEditRostersContainerComponent,
  },
  {
    path: 'rosters/:rosterId/add',
    component: AddEditStudentsContainerComponent,
  },
  {
    path: 'rosters/:rosterId/student/:studentId',
    component: AddEditStudentsContainerComponent,
  },
  {
    path: 'analytics',
    component: AnalyticsContainerComponent,
  },
  {
    path: 'functions',
    component: FunctionsContainerComponent,
  },
  {
    path: 'functions/add',
    component: AddEditFunctionsContainerComponent,
  },
  {
    path: 'functions/:id',
    component: AddEditFunctionsContainerComponent,
  },
  {
    path: 'roles',
    component: RolesContainerComponent,
  },
  {
    path: 'roles/new',
    component: AddEditRoleContainerComponent,
  },
  {
    path: 'roles/:id',
    component: AddEditRoleContainerComponent,
  },
  {
    path: 'users',
    component: UsersContainerComponent,
  },
  {
    path: 'users/add',
    component: AddEditUserContainerComponent,
  },
  {
    path: 'users/:id/edit',
    component: AddEditUserContainerComponent,
  },
  {
    path: 'database/songs',
    component: SongsContainerComponent,
  },
  {
    path: 'database/songs/add',
    component: AddEditSongContainerComponent,
  },
  {
    path: 'database/songs/:id/edit',
    component: AddEditSongContainerComponent,
  },
  {
    path: 'database/producers',
    component: ProducersContainerComponent,
  },
  {
    path: 'database/producers/add',
    component: AddEditProducerContainerComponent,
  },
  {
    path: 'database/producers/:id/edit',
    component: AddEditProducerContainerComponent,
  },
  {
    path: 'cards',
    component: CardsComponent,
  },
  {
    path: 'cards/edit/:id',
    component: EditCardComponent,
  },

  {
    path: 'database/artists',
    component: ArtistsComponent,
  },
  {
    path: 'database/artists/add',
    component: AddEditArtistComponent,
  },
  {
    path: 'database/artists/:id',
    component: AddEditArtistComponent,
  },

  {
    path: 'database/albums',
    component: AlbumsComponent,
  },
  {
    path: 'database/albums/add',
    component: AddEditAlbumComponent,
  },
  {
    path: 'database/albums/:id/edit',
    component: AddEditAlbumComponent,
  },


  {
    path: 'contributions/songs',
    component: SongsContainerComponent,
    data: { isContributions: true }
  },
  {
    path: 'contributions/songs/add',
    component: AddEditSongContainerComponent,
    data: { isContributions: true }
  },
  {
    path: 'contributions/songs/:id/edit',
    component: AddEditSongContainerComponent,
    data: { isContributions: true }
  },
  {
    path: 'contributions/producers',
    component: ProducersContainerComponent,
    data: { isContributions: true }
  },
  {
    path: 'contributions/producers/add',
    component: AddEditProducerContainerComponent,
    data: { isContributions: true }
  },
  {
    path: 'contributions/producers/:id/edit',
    component: AddEditProducerContainerComponent,
    data: { isContributions: true }
  },

  {
    path: 'contributions/artists',
    component: ArtistsComponent,
    data: { isContributions: true }
  },
  {
    path: 'contributions/artists/add',
    component: AddEditArtistComponent,
    data: { isContributions: true }
  },
  {
    path: 'contributions/artists/:id/edit',
    component: AddEditArtistComponent,
    data: { isContributions: true }
  },

  {
    path: 'contributions/albums',
    component: AlbumsComponent,
    data: { isContributions: true }
  },
  {
    path: 'contributions/albums/add',
    component: AddEditAlbumComponent,
    data: { isContributions: true }
  },
  {
    path: 'contributions/albums/:id/edit',
    component: AddEditAlbumComponent,
    data: { isContributions: true }
  },
  {
    path: 'trending',
    component: TrendingWordsComponent,
  },

];
