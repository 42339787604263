<rap-breadcrumb-edit-new [exploreLabel]="'Rap Almanac'" [exploreLink]="'/dashboard/explore'"
    [exploreImage]="'../assets/images/filled.svg'" [subLabel]="'Cards'" [subLink]="'/dashboard/cards'"
    [subId]="{ id: id }" [isNew]="false" [isView]="false" [filledImage]="'../assets/images/filled.svg'"
    [title]=""></rap-breadcrumb-edit-new>

<div style="padding: 0px 10px;">
    <div class="section-title">
        Edit Card
    </div>
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
    <div class="card" *ngIf="cardItem !== null">
        <div class="card-body" style="max-width: 50%;">
            <div>
                Image
            </div>
            <img *ngIf="imagePath !== null" [src]="imagePath" width="200" style="margin-bottom: 20px;">
            <div style="margin-bottom: 20px;" *ngIf="imagePath === null">
                <button type="button" class="btn btn-light action-button" (click)="onUploadImage()">
                    Upload Image
                </button>
            </div>
            <div style=" margin-bottom: 20px;">
                Chart Preview
            </div>
            <div *ngIf="cardItem?.chartType !== null" style="padding-bottom: 20px;">
                <div *ngIf="cardItem.chartType === 'Bar chart'">
                    <canvasjs-chart [options]="getChartOptions(cardItem)"
                        [styles]="{ width: '100%', height:'300px'}"></canvasjs-chart>
                </div>
                <div *ngIf="cardItem.chartType === 'Timeline'">
                    <canvasjs-chart [options]="getChartOptionsTimeLine(cardItem)"
                        [styles]="{ width: '80%', height:'300px'}"></canvasjs-chart>
                </div>
                <div *ngIf="cardItem.chartType === 'Nearest Neighbour'">
                    <rap-word-count-chart [data]="cardItem.data[0]" [queryWord]=""></rap-word-count-chart>
                </div>
            </div>

            <div style="width: 100%; margin-bottom: 20px;">
                <div>
                    Text
                </div>
                <quill-editor [(ngModel)]="editorContent" class="content-editor" [placeholder]="'Enter text here...'"
                    [modules]="{ toolbar: [['bold', 'italic', 'underline'], ['clean'],   [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                   [{ 'script': 'sub' }, { 'script': 'super' }],    
                                                                    [{ 'indent': '-1' }, { 'indent': '+1' }],  [{ 'direction': 'rtl' }],            ] }"
                    ngDefaultControl></quill-editor>
                <div [innerHTML]="model"></div>
                {{model}}
            </div>

            <div class="role-action-btns">
                <rap-rect-primary [label]="'Save'" [btnClass]="'btn btn-dark rect-primary-btn1'"
                    (clickEvent)="onSave()"></rap-rect-primary>
                <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light  rect-secondary-btn1'"
                    (clickEvent)="onReset()"></rap-rect-primary>
                <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light  rect-secondary-btn1'"
                    (clickEvent)="onCancel()"></rap-rect-primary>

            </div>
        </div>
    </div>
</div>