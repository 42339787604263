<rap-breadcrumb-default [exploreLabel]="'Rap Almanac'" [exploreLink]="'/dashboard/explore'"
  [exploreImage]="'../assets/images/filled.svg'" [rostersLabel]="'Functions'"
  (rostersClick)="onFunctions()"></rap-breadcrumb-default>

<div style="padding: 0px 10px;">
  <div class="section-title">Functions</div>
  <rap-rect-primary [label]="'Add New'" [btnClass]="'btn btn-dark'" (clickEvent)="onAdd()"></rap-rect-primary>

  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
</div>

<rap-search-input [(searchValue)]="filters.search" (search)="getSearchResults($event)"
  (clear)="onClearSearch()"></rap-search-input>
<div style="padding: 0px 10px;">
  <ng-container *ngIf="functions?.length > 0">
    <div class="custom-container" style="margin-top: 20px;">
      <div class="functions-table" *ngIf="functions?.length > 0">
        <table class="table rounded-4 overflow-hidden">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let function of paginatedFunctions">
              <td scope="row">{{ function?.name }}</td>
              <td>
                <rap-rect-primary [label]="'Edit'" [btnClass]="'btn btn-light rect-secondary-btn'"
                  (clickEvent)="editFunction(function)"></rap-rect-primary>
                <rap-rect-primary [label]="'Delete'" [btnClass]="'btn btn-dark rect-primary-btn'"
                  (clickEvent)="deleteFunction(function.id)"></rap-rect-primary>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pagination">
          <rap-pagination [collectionSize]="functions?.length" [page]="page" [pageSize]="pageSize"
            (onPageChange)="onPageChange($event)"></rap-pagination>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<rap-no-data [data]="functions" [isLoading]="isLoading" [filters]="filters"
  [message]="'No functions found'"></rap-no-data>