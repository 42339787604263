import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Song } from '../models/songs/song.model';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Producer } from '../models/producers/producer.model';
import { BaseTemplateService } from '../base-template/base-template.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';

@Component({
  selector: 'rap-producers-ui',
  templateUrl: './producers.component.html',
  styleUrls: ['./producers.component.scss'],
})
export class ProducersComponent {
  @Input() producers: any;
  @Output() deleteProducerEmitter = new EventEmitter<string>();
  @Output() createProducerEmitter = new EventEmitter();
  @Output() editProducerEmitter = new EventEmitter<Producer>();
  @Output() searchProducerEmitter = new EventEmitter<{ search: string, page: number }>();
  page = 1;
  pageSize = 10;
  isLoading = false;
  producersData: any;
  pagesCount = 0;
  isContributions: boolean = false;
  title = "Database"
  filters: any = {
    search: ""
  };
  constructor(private modalService: NgbModal,
    private baseTemplateService: BaseTemplateService,
    private route: ActivatedRoute,
    private toastr: ToastrService, private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.baseTemplateService.showHideSearch(false);

    this.route.data.subscribe(data => {
      console.log(data["isContributions"])
      if (data["isContributions"])
        this.title = "Contributions"
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['producers'] && this.producers) {
      this.isLoading = false;
      // this.page = 1;
      console.log(changes['producers']);
      this.producersData = this.producers.rows;
      console.log(this.producersData);
      console.log(this.pagesCount);
      if (this.page == 1)
        this.pagesCount = this.producers.count / 10;
    }
  }

  get paginatedProducers(): Song[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    console.log(this.producersData)
    // console.log(end)
    // console.log(this.songsData.slice(start, end))
    return this.producersData;
  }

  onPageChange(page: number) {
    this.page = page;
    console.log(this.page)
    this.isLoading = true;
    this.searchProducerEmitter.emit({
      search: '',
      page: page
    });
  }

  getSearchResults(search: string) {
    this.isLoading = true;
    this.filters.search = search
    this.searchProducerEmitter.emit({
      search: this.filters.search,
      page: this.page
    });
  }

  editProducer(producer: Producer) {
    // this.editProducerEmitter.emit(producer);
    this.toastr.success('Feature Coming Soon!!!', '', {
      positionClass: 'toast-top-center',
      closeButton: false,
    });
  }

  deleteProducer(producerId: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = producerId;
    modalRef.result.then((id) => {
      this.isLoading = true;
      this.deleteProducerEmitter.emit(id);
    });
  }
  onClearSearch() {
    this.filters.search = "";
    this.getSearchResults("")
  }

}
