<div class="label-container">
  <div>
    <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
    <img src="../assets/images/filled.svg" />
  </div>
  <div>
    <label class="non-clickable-label">App</label>
    <img src="../assets/images/filled.svg" />
  </div>
  <label class="clickable-label" routerLink="/dashboard/rosters">Rosters</label>
  <div>
    <img src="../assets/images/filled.svg" />
    <label class="non-clickable-label">{{rosterId}}</label>
  </div>
  <div>
    <img src="../assets/images/filled.svg" />
    <label class="clickable-label">Student</label>
  </div>
  <div *ngIf="isNew">
    <img src="../assets/images/filled.svg" *ngIf="studentId !== null" />
    <label class="non-clickable-label" *ngIf="studentId !== null">{{studentId}}</label>
  </div>
  <div *ngIf="isNew">
    <img src="../assets/images/filled.svg" />
    <label class="non-clickable-label" *ngIf="studentId !== null">{{ studentId == null ? 'New' : 'Edit' }}</label>
  </div>
</div>

<div style="padding: 0px 10px;">
  <div class="section-title">
    {{ studentId == null ? 'Add Student to Roster' : 'Edit Student' }}
  </div>
  <div class="error-container" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>

  <div class="card">
    <div class="card-body">
      <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
      <form [formGroup]="studentForm" (ngSubmit)="addUpdateStudent()">
        <div class="form-group">
          <label for="email">Student Name</label>
          <div class="input-container">
            <input type="text" class="search-input" [class.submitted]="submitted" formControlName="studentName" />
          </div>
          <div *ngIf="submitted && f['studentName'].errors" class="rap-error-text">
            <span *ngIf="f['studentName'].errors?.['required']">Name is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Student Code</label>
          <div class="input-container">
            <input type="text" class="search-input" [class.submitted]="submitted" formControlName="studentCode"
              readonly="true" />
          </div>
        </div>
        <div *ngIf="submitted && f['studentCode'].errors" class="rap-error-text">
          <span *ngIf="f['studentCode'].errors?.['required']">Code is required</span>
        </div>
        <div class="action-btns">
          <rap-rect-primary [label]="btnTitle" [btnClass]="'btn btn-dark rect-primary-btn1'"
            (clickEvent)="addUpdateStudent()"></rap-rect-primary>
          <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn1'"
            (clickEvent)="onCancel()"></rap-rect-primary>
        </div>
      </form>
    </div>
  </div>
</div>