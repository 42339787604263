<div class="sign-in">
  <div class="left">
    <!-- <rap-spinner class="loader-view" *ngIf="isLoading"></rap-spinner> -->
    <div class="container">
      <h1>{{ title }}</h1>
      <p>{{ subTitle }}</p>
      <div class="error-container" *ngIf="error">
        {{ error }}
      </div>

      <div class="form-group">
        <label for="code">Enter code</label>
        <div class="input-container">
          <input type="text" class="search-input" [class.submitted]="submitted"
            [class.ng-invalid]="!emailVerificationCode" placeholder="Enter code" [(ngModel)]="emailVerificationCode" />
          <i class="auth-icons bi bi-shield-fill"></i>
        </div>
        <div *ngIf="submitted && !emailVerificationCode" class="rap-error-text">
          <span>Code is required</span>
        </div>
      </div>

      <!-- <button type="submit" class="btn-signin" (click)="verifyEmail()">
        Verify
      </button> -->
      <rap-rect-button-loader [isLoading]="isLoading" [disabled]="false" buttonClass="btn btn-dark btn-signin"
        style="padding-top: 10px;" type="submit" (clickEvent)="verifyEmail()">
        Verify
      </rap-rect-button-loader>
      <rap-terms-privacy></rap-terms-privacy>
    </div>
  </div>
  <div class="right"></div>
</div>