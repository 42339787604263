<div class="label-container">
  <div>
    <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
    <img src="../assets/images/filled.svg" />
  </div>
  <label (click)="onAnalytics()" *ngIf="viewAnalytics === null" class="non-clickable-label"
    style=" text-decoration: none;">Analytics</label>
  <label (click)="onAnalytics()" *ngIf="viewAnalytics !== null" class="non-clickable-label">Analytics</label>
  <div *ngIf="viewAnalytics !== null">
    <img src="../assets/images/filled.svg" />
    <label class="non-clickable-label" style=" text-decoration: none;">{{ viewAnalytics?.id}}</label>
  </div>
</div>

<div class="section-body">
  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <div class="section-title"> {{ viewAnalytics === null ? 'Queries' : 'View Student Queries' }}</div>
  <div class="error-container" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>

<rap-search-input [(searchValue)]="filters.search" (search)="getSearchResults($event)"
  (clear)="onClearSearch()"></rap-search-input>

<div style="padding: 0px 10px;">
  <ng-container *ngIf="analytics?.length > 0">
    <div class="custom-container">
      <table class="table rounded-4 overflow-hidden">
        <thead class="table-header">
          <tr>
            <th scope="col"> {{ viewAnalytics === null ? 'Student' : 'Query' }}</th>
            <th scope="col" *ngIf="viewAnalytics == null">Roster</th>
            <th scope="col" *ngIf="viewAnalytics == null">Actions</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let analytic of paginatedAnalytics">
            <td scope="row">{{ viewAnalytics === null ? analytic?.student?.firstName : analytic.query_string }}</td>
            <td *ngIf="viewAnalytics == null">{{ analytic?.roster?.name }}</td>
            <td *ngIf="viewAnalytics == null">

              <rap-rect-primary [label]="'View Queries'" [btnClass]="'btn btn-light rect-secondary-btn'"
                (clickEvent)="viewQuery(analytic.id)"></rap-rect-primary>
              <rap-rect-primary [label]="'Delete'" [btnClass]="'btn btn-dark rect-primary-btn'"
                (clickEvent)="deleteQuery(analytic.id)"></rap-rect-primary>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <div class="pagination">
      <rap-pagination [collectionSize]="analytics?.length" [page]="page" [pageSize]="pageSize"
        (onPageChange)="onPageChange($event)"></rap-pagination>
    </div>
  </ng-container>
</div>



<rap-no-data [data]="analytics" [isLoading]="isLoading" [filters]="filters" [message]="'No rosters found'"
  [view]="viewAnalytics"></rap-no-data>