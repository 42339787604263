import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'rap-breadcrumb-edit-new',
  templateUrl: './breadcrumb-edit-new.component.html',
  styleUrls: ['./breadcrumb-edit-new.component.scss']
})
export class BreadcrumbEditNewComponent {
  @Input() exploreLabel: string = 'Rap Almanac';
  @Input() exploreLink: string = '/dashboard/explore';
  @Input() exploreImage: string = '../assets/images/filled.svg';

  @Input() subLabel: string = 'Rosters';
  @Input() subLink: string = '';

  @Input() subId: { id: string } | null = null;
  @Input() isNew: boolean = false;
  @Input() isView: boolean = false;
  @Input() filledImage: string = '../assets/images/filled.svg';

  @Input() title: string = '';

  constructor(private router: Router) {
    console.log(this.subId)
  }

  ngOnInit(): void {
    console.log(this.subId)
    console.log(this.isNew)
  }
  moveToExplore() {
    console.log("vkjvkj")
    this.router.navigate(['/dashboard/explore']);
  }
  onClick() {
    console.log(this.subLink)
    if (this.subLink != '')
      this.router.navigate([this.subLink]);
  }
}
