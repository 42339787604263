<div class="label-container">

    <div>
        <label class="clickable-label" (click)=moveToExplore()>{{ exploreLabel }} </label>
        <img src="../assets/images/filled.svg" />
    </div>

    <label (click)="onClick()" class="clickable-label">{{ subLabel }}</label>

    <div *ngIf="subId.id !== null && isNew === false">
        <img [src]="filledImage" />
        <label class="non-clickable-label">{{ subId.id }}</label>
    </div>

    <div *ngIf="isView === false">
        <img [src]="filledImage" />
        <label class="non-clickable-label" style=" text-decoration: none;">{{isNew ? 'New' : 'Edit'}}</label>
    </div>
</div>